
<template>
  <ul class="vuejs-countdown">
    <li v-if="days > 0">
      <p :class="danger ? 'digit text-danger' : 'digit'">
        {{ days | twoDigits }}
      </p>
      <p :class="danger ? 'text text-danger' : 'text'">
        {{ days > 1 ? "hari" : "hari" }}
      </p>
    </li>
    <li>
      <p :class="danger ? 'digit text-danger' : 'digit'">
        {{ hours | twoDigits }}
      </p>
      <p :class="danger ? 'text text-danger' : 'text'">
        {{ hours > 1 ? "jam" : "jam" }}
      </p>
    </li>
    <li>
      <p :class="danger ? 'digit text-danger' : 'digit'">
        {{ minutes | twoDigits }}
      </p>
      <p :class="danger ? 'text text-danger' : 'text'">menit</p>
    </li>
    <li>
      <p :class="danger ? 'digit text-danger' : 'digit'">
        {{ seconds | twoDigits }}
      </p>
      <p :class="danger ? 'text text-danger' : 'text'">detik</p>
    </li>
  </ul>
</template>

<script>
let interval = null;
export default {
  name: "vuejsCountDown",
  props: {
    deadline: {
      type: String,
    },
    end: {
      type: String,
    },
    stop: {
      type: Boolean,
    },
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: null,
      diff: 0,
      today: 1,
      minute_now: 1,
      hour_now: 1,
      danger: false,
      is_warning: true,
    };
  },
  methods: {},
  mounted() {},
  created() {
    if (!this.deadline && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let endTime = this.deadline ? this.deadline : this.end;
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }
    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
      this.diff = this.date - this.now;
    }, 100);
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },
    minutes() {
      let minute = Math.trunc(this.diff / 60) % 60;
      this.minute_now = minute;
      return minute;
    },
    hours() {
      let hour = Math.trunc(this.diff / 60 / 60) % 24;
      this.hour_now = hour;
      // //("jam di computed =================", hour)
      // //("diff di computed ================", this.diff)
      return hour;
    },
    days() {
      let today = Math.trunc(this.diff / 60 / 60 / 24);
      this.today = today;
      return today;
    },
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        // Remove interval
        clearInterval(interval);
      }
      // this.handleWarning()
    },
    diff(value) {
      if (value === 0) {
        this.$emit("timeElapsed");
      } else if (value <= 600) {
        if (this.is_warning == true) {
          this.$emit("handleWarning");
          this.danger = true;
          this.is_warning = false;
        }
      }
    },
    deadline: function (newVal, oldVal) {
      // watch it
      //("deadline changed: ", newVal, " | was: ", oldVal);
      
      if (!this.deadline && !this.end) {
        throw new Error("Missing props 'deadline' or 'end'");
      }
      let endTime = this.deadline ? this.deadline : this.end;
      this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
      if (!this.date) {
        throw new Error("Invalid props value, correct the 'deadline' or 'end'");
      }
      interval = setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000);
        this.diff = this.date - this.now;
      }, 100);
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },
  destroyed() {
    clearInterval(interval);
  },
};
</script>
<style>
.vuejs-countdown {
  padding: 0;
  margin: 0;
}
.vuejs-countdown li {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
  position: relative;
}
.vuejs-countdown li p {
  margin: 0;
}
.vuejs-countdown li:after {
  content: ":";
  position: absolute;
  top: 0;
  right: -13px;
  font-size: 32px;
}
.vuejs-countdown li:first-of-type {
  margin-left: 0;
}
.vuejs-countdown li:last-of-type {
  margin-right: 0;
}
.vuejs-countdown li:last-of-type:after {
  content: "";
}
.vuejs-countdown .digit {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.vuejs-countdown .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 10px;
}
</style>
<template>
  <div>
    <template v-if="content == 'list'">
      <table>
        <tr>
          <th>PG:</th>
          <td class="pl-1">Pilihan Ganda</td>
        </tr>
        <tr>
          <th>BS:</th>
          <td class="pl-1">Benar Salah</td>
        </tr>
        <tr>
          <th>Es:</th>
          <td class="pl-1">Essay</td>
        </tr>
      </table>
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        class="mt-3"
        responsive
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'action' ? '25%' : field.key === 'score' ? '10%' : '' }"
          />
        </template>

        <template #cell(number)="data" v-if="dataLoaded">
          {{ data.index + 1 }}
        </template>

        <template #cell(classwork_name)="data" v-if="dataLoaded">
          <span v-if="data.item.assignment_id">{{
            data.item.assignment_title
          }}</span>
          <span v-if="data.item.quiz_id">{{ data.item.quiz_title }}</span>
        </template>

        <template #cell(competence_standard)="data" v-if="dataLoaded">
          <span>{{data.item.competence_standard ? data.item.competence_standard.code : ''}}</span> 
          <span>{{data.item.competence_standard ? data.item.competence_standard.name : ''}}</span>
        </template>

        <template #cell(score)="data" v-if="dataLoaded">
          <span v-if="data.item.assignment_id">{{
            data.item.final_score
          }}</span>
          <template v-if="data.item.quiz_id">
            <span class="d-block "><b>PG:</b> {{data.item.choice_score}}</span>
            <span class="d-block"><b>BS:</b> {{data.item.true_false_score}}</span>
            <span class="d-block"><b>Es:</b> {{data.item.essay_score}}</span>
          </template>
        </template>

        <template #cell(final_score)="data" v-if="dataLoaded">
          <span>{{ data.item.final_score }}</span>
        </template>

        <template #cell(action)="data" v-if="dataLoaded">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              handleDetailAssignment(
                data.item.classwork_id,
                data.item.classwork_type_id,
                data.item.student_id,
                data.item.student_name
              )
            "
            v-if="data.item.assignment_id"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              handleDetailQuiz(
                data.item.classwork_id,
                2,
                data.item.quiz_id,
                data.item.student_id,
                data.item.student_name,
                data.item.is_finish
              )
            "
            v-if="data.item.quiz_id"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <!-- <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="
            $router.push({
              path: '/school-report-card/detail/quiz/' + data.item.id,
            })
          "
          ><i class="fas fa-edit px-0"></i
        ></b-button> -->
          <!-- <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          @click="deleteData(data.item.id)"
          ><i class="fas fa-trash px-0"></i
        ></b-button> -->
        </template>
      </b-table>

      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </template>
    <template v-if="content == 'detail-assignment'">
      <DetailAssignmentRecapValue
        @handleContent="handleContent"
        :detail="detailClasswork"
        :studentId="student_id"
        :studentName="student_name"
        :availableAnswer="available_answer"
      />
    </template>
    <template v-if="content == 'detail-quiz'">
      <DetailQuizRecapValue
        @handleContent="handleContent"
        :detail="detailClasswork"
        :available_answer="available_answer"
        :studentId="student_id"
        :studentName="student_name"
        :statusQuiz="is_finish"
      />
    </template>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import DetailAssignmentRecapValue from "@/view/components/school-report-card/DetailAssignmentRecapValue.vue";
import DetailQuizRecapValue from "@/view/components/school-report-card/DetailQuizRecapValue.vue";

export default {
  props:{
    purpose: String
  },
  components: {
    DetailAssignmentRecapValue,
    DetailQuizRecapValue,
  },
  data() {
    return {
      // content
      content: "list",
      // filter
      filter: {
        user_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "classroom_name",
          label: "Kelas",
          sortable: true,
        },
        {
          key: "final_checked_by_name",
          label: "Guru",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Siswa",
          sortable: true,
        },
        {
          key: "classwork_name",
          label: "Tugas/Kuis",
          sortable: true,
        },
        {
          key: "score",
          label: "Nilai",
          sortable: true,
        },
        {
          key: "final_score",
          label: "Nilai Akhir",
          sortable: true,
        },
        {
          key: "competence_standard",
          label: "Standar Kompetensi",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // detail assignment
      detailClasswork: [],
      student_name: [],
      available_answer: [],
      // detail quiz
      is_finish: '',
      // other
      dataLoaded: false
    };
  },
  methods: {
    handleContent(content) {
      this.content = content;
    },
   async handleDetailAssignment(classwork_id, classwork_type_id, student_id, student_name) {
      this.student_name = student_name;
      // classworks/{classworkId}/{classworkTypeId}
      this.detailClasswork = await module.get(
        `api/classworks/${classwork_id}/${classwork_type_id}`
      );
      // If Data Not Found
      if (this.detailClasswork == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.detailClasswork = this.detailClasswork[0];
        // classwork assignment
        //("detailClasswork", this.detailClasswork);
        this.getClassworkAssignment(
          this.detailClasswork.classwork_id,
          this.detailClasswork.assignment_id,
          student_id
        );
      }
    },

    async getClassworkAssignment(classworkId, assignment_id, student_id) {
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-assignment-results/${assignment_id}/${student_id}`
      );
      this.available_answer = response.data;
      if (this.available_answer[0].is_correct == 0) {
        this.available_answer[0].is_active = false;
      } else {
        this.available_answer[0].is_active = true;
      }
      //("classwork assignment ============", this.available_answer);
      this.content = "detail-assignment";
      this.$root.$emit("refreshScoreContent", "correction");
      this.$root.$emit("handleContentRecapValue", "detail");
    },

    async handleDetailQuiz(
      classwork_id,
      classwork_type_id,
      quiz_id,
      student_id,
      student_name,
      is_finish
    ) {
      this.student_id = student_id;
      this.student_name = student_name;
      this.is_finish = is_finish;
      // //("is_finish", is_finish);
      // //("content", this.content);
      // classworks/{classworkId}/{classworkTypeId}
      this.detailClasswork = await module.get(
        `api/classworks/${classwork_id}/${classwork_type_id}`
      );
      // If Data Not Found
      if (this.detailClasswork == null) {
        // Redirect To List
        this.content = "list";
      } else {
        this.detailClasswork = this.detailClasswork[0];
        // classwork quiz

        for (let c = 0; c < this.detailClasswork.questions.length; c++) {
          this.detailClasswork.questions[c].number = c + 1;
          if (this.detailClasswork.questions[c].answer_choices != "") {
            if (
              this.detailClasswork.questions[c].question_type_id == 4 ||
              this.detailClasswork.questions[c].question_type_id == 5
            ) {
              var answer_reference = JSON.parse(
                this.detailClasswork.questions[c].answer
              );
            }
            this.detailClasswork.questions[c].answer_choices_display =
              JSON.parse(this.detailClasswork.questions[c].answer_choices);
            this.detailClasswork.questions[c].answer_choices_display_reference =
              JSON.parse(this.detailClasswork.questions[c].answer_choices);
            if (
              this.detailClasswork.questions[c].question_type_id == 4 ||
              this.detailClasswork.questions[c].question_type_id == 5
            ) {
              for (
                let d = 0;
                d <
                this.detailClasswork.questions[c]
                  .answer_choices_display_reference.length;
                d++
              ) {
                for (let e = 0; e < answer_reference.length; e++) {
                  if (
                    this.detailClasswork.questions[c]
                      .answer_choices_display_reference[d].value ==
                    answer_reference[e]
                  ) {
                    this.detailClasswork.questions[
                      c
                    ].answer_choices_display_reference[d].checked = true;
                  }
                }
              }
            }
          }
        }
        //("detailClasswork", this.detailClasswork);
        this.getClassworkQuiz(
          this.detailClasswork.classwork_id,
          this.detailClasswork.quiz_id,
          student_id
        );
      }
    },

    async getClassworkQuiz(classworkId, quizId, studentId) {
      // classrooms/{classroomId}/classworks/{classworkId}/classwork-quiz-results/{quizId}
      let response = await module.paginate(
        `api/classrooms/${this.classroom_id}/classworks/${classworkId}/classwork-quiz-results/${quizId}/${studentId}`
      );
      this.available_answer = response.data;
      //("classwork quiz =========================", response);
      this.content = "detail-quiz";
      this.$root.$emit("refreshScoreContent", "correction");
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      //   let filterParams = `&user_id=${this.filter.user_id}`;
      // class-group
      let filterParams = `&user_id=${this.filter.user_id}`;
      if(this.purpose == 'class-group'){
        filterParams = `&class_group_id=${this.$route.params.classGroupid}`
      }

      let quiz_scores = await module.paginate(
        `api/classwork-quiz-scores`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      //("quiz", quiz_scores);

      for (let a = 0; a < quiz_scores.data.length; a++) {
        this.items.push(quiz_scores.data[a]);
      }
      let assignment_scores = await module.paginate(
        `api/classwork-assignment-scores`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      //("assignment", assignment_scores);
      for (let b = 0; b < assignment_scores.data.length; b++) {
        this.items.push(assignment_scores.data[b]);
      }
      setTimeout(() => {
        this.items.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
        this.dataLoaded = true
      }, 4000);

      //("items", this.items);
    },

    fixItem() {
      //   this.items.sort((a, b) => (a.created_by > b.created_by ? 1 : -1))
      setTimeout(() => {
        this.fixNumber();
      }, 1000);
    },
    fixNumber() {
      for (let a = 0; a < this.items.length; a++) {
        this.items[a].number = a + 1;
      }
    },
  },
  mounted() {
    if(this.purpose == 'class-group'){
      this.pagination()
    }

    this.$root.$on("refreshTableRecapValue", (filter) => {
      this.items = [];
      this.filter.user_id = filter.user_id;
      this.pagination();
    });
  },
  watch: {
    filter: function (newVal, oldVal) {
      // watch it
      //("Filter changed: ", newVal, " | was: ", oldVal);
      //   this.pagination();
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            Detail Kelompok Kelas
          </h5>
          <table class="table mt-8">
            <tr>
              <th class="pr-1" width="150">Nama</th>
              <td class="pl-0 pr-0" width="10">:</td>
              <td class="pl-2">
                <span class="">{{ detail.name }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="150">Tingkat</th>
              <th class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </th>
              <td class="pl-2">
                <span class="">{{ detail.grade_name }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="150">Prodi</th>
              <th class="pl-0 pr-0" width="10">:</th>
              <td class="pl-2">
                <span class="">{{ detail.major_name }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="150">Angkatan</th>
              <th class="pl-0 pr-0" width="10">:</th>
              <td class="pl-2">
                <span class="">{{ detail.school_year_name }}</span>
              </td>
            </tr>
            <!-- <tr>
              <th class="pr-1" width="150">Jenis Periode</th>
              <th class="pl-0 pr-0" width="10">:</th>
              <td class="pl-2">
                <span class="">{{ detail.periode_type_name }}</span>
              </td>
            </tr> -->
            <tr>
              <th class="pr-1" width="150">Jumlah Murid</th>
              <th class="pl-0 pr-0" width="10">:</th>
              <td class="pl-2">
                <span class="">{{ total_students }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="150">Wali Kelas</th>
              <th class="pl-0 pr-0" width="10">:</th>
              <td class="pl-2">
                <span class="">{{ detail.user_name }}</span>
              </td>
            </tr>
          </table>

          <b-tabs content-class="my-3" @input="inputTabs">
            <b-tab title="Daftar Siswa"></b-tab>
            <!-- <b-tab title="Rekap Absen"></b-tab>
            <b-tab title="Rekap Nilai"></b-tab>
            <b-tab title="Grafik Kemajuan Siswa"></b-tab> -->
          </b-tabs>

          <!-- Daftar Siswa -->
          <template v-if="orderTabActive == 0">
            <b-table
              striped
              hover
              :items="students"
              :fields="fields"
              class="mt-3"
              responsive
              :class="'nowrap'"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>
              <template #cell(participant_photo)="data">
                <div
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    image-container
                  "
                >
                  <div class="image-input-wrapper" style="position: relative">
                    <img class="image" :src="data.item.participant_photo" />
                  </div>
                </div>
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.participant_id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
          </template>
          <template v-if="orderTabActive == 1">
            <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
              <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <template v-if="currentItem == 'tab-Absen Sekolah'">
              <!-- <TablePresence /> -->
            </template>
            <template v-if="currentItem == 'tab-Absen Ruang Kelas'">
              <h2>Absen Kelas</h2>
            </template>
          </template>

         

          <template v-if="orderTabActive == 2">
            <TableRecapValue purpose="class-group" />
          </template>

          <div class="d-flex justify-content-between pt-10">
            <div>
              <b-button
                type="button"
                class="ml-2"
                variant="primary"
                @click="$router.push('/masters/class-groups')"
              >
                Tutup
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TablePresence />
    
    <ModalUser
      purpose="modal-user"
      :route="`api/users/get-available-users-for-class-groups/${detail.id}`"
      :selectedItem="participant_id"
      :filterParticipant="filter"
      @chooseUser="setAddParticipant"
      @formOnSubmit="formOnSubmit"
      secondPurpose="addClassGroupMember"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import TablePresence from "@/view/components/presence/Table.vue";
import TableRecapValue from "@/view/components/school-report-card/TableRecapValue";
import ModalUser from "@/view/components/general/ModalUser.vue";

export default {
  components: {
    TablePresence,
    ModalUser,
    TableRecapValue
  },
  data() {
    return {
      currentItem: "tab-Absen Sekolah",
      items: ["Absen Sekolah", "Absen Ruang Kelas"],
      display: {
        participant_name: "",
      },
      filter: {
        role_id: "",
      },
      dataLoaded: false,
      classroom_id: "",
      // classroom detail

      // participants
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "participant_photo",
          label: "Foto",
        },
        {
          key: "participant_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIS",
          sortable: true,
        },
        {
          key: "major_name",
          label: "Prodi",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      detail: [],
      students: [],
      teacher: {},
      total_students: [],
      participant_id: [],
      form: {
        class_group_id: "",
        user_id: "",
      },
      // other
      orderTabActive: 0,
    };
  },
  components: {
    ModalUser,
    TableRecapValue,
  },
  methods: {
    inputTabs(evt) {
      this.orderTabActive = evt;
    },
    async getClassGroupById() {
      let response = await module.getFullResponse(
        `api/class-group-users/class-groups/${this.$route.params.classGroupid}/${this.$route.params.teacherId}`
      );
      // If Data Not Found
      if (response == null) {
        // Redirect To List
      } else {
        this.detail = response.data.data;
        this.teacher = response.data.meta.teacher;
        this.total_students = response.data.meta.total_students;
        //("total_students", this.total_students);
        //("detail", this.detail);
        //("teacher", this.teacher);

        let a, b;
        b = this.perPage * (this.currentPage - 1) + 1;
        for (a = 0; a < response.data.meta.students.length; a++) {
          response.data.meta.students[a].number = b + a;
        }
        this.students = response.data.meta.students;
        //("students", this.students);
      }
    },
    showModalUser(role_id) {
      this.filter.role_id = role_id;
      this.$bvModal.show("modal-user");
    },
    async deleteData(id) {
      // // Delete Data
      // //   class-group-users/class-groups/{classGroupId}/users/{userId}/detach
      // let result = await module.delete(
      //   `api/class-group-users/class-groups/${this.detail.id}/users/${id}/detach`
      // );
      // // If Deleted
      // if (result) {
      //   this.getClassGroupById();
      // }
      this.getClassGroupById();
    },
    setAddParticipant(value) {
      if (this.participant_id.length == 0) {
        let clone = { ...value };
        this.participant_id.push(clone);
        //("participant_id", this.participant_id);
        this.displayParticipantName(this.participant_id);
      } else {
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          if (this.participant_id[a].id == value.id) {
            this.participant_id.splice(a, 1);
            if (this.participant_id.length == 0) {
              this.display.participant_name = "";
              break;
            } else if (this.participant_id.length > 0) {
              this.displayParticipantName(this.participant_id);
              break;
            }
            break;
          } else if (a == this.participant_id.length - 1) {
            let clone = { ...value };
            this.participant_id.push(clone);
            //("participant_id", this.participant_id);
            this.displayParticipantName(this.participant_id);
            break;
          }
        }
      }
    },
    displayParticipantName(participant) {
      if (participant.length == 0) {
        this.display.participant_name = "";
      } else {
        let str = "";
        participant.forEach(function (value, index) {
          str += value.name;
          if (index != participant.length - 1) {
            if (participant.length > 1) str += ", ";
          }
        });
        this.display.participant_name = str;
      }
    },
    resetParticipant() {
      this.participant_id.splice(0, this.participant_id.length);
      //("reset", this.participant_id);
      this.display.participant_name = "";
    },
    async formOnSubmit() {
      if (this.participant_id.length > 0) {
        SwalLoading.fire();
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          this.form.user_id = this.participant_id[a].id;
          this.form.class_group_id = this.detail.id;
          //   class-group-users/class-groups/{classGroupId}/users/{userId}/attach
          let response = await module.submitParticipant(
            this.form,
            `api/class-group-users/class-groups/${this.detail.id}/users/${this.form.user_id}/attach`
          );

          if (a == this.participant_id.length - 1) {
            this.getClassGroupById();
            SwalLoading.close();
            Swal.fire(
              response.success.title,
              response.success.message,
              "success"
            );
            this.display.participant_name = "";
            this.participant_id = [];
          }
        }
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum memilih peserta",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.error.name = "Peserta harus diisi";
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kelompok Kelas", route: "/classrooms" },
      { title: "Detail Kelompok Kelas" },
    ]);

    this.getClassGroupById();
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
<template>
  <div>
    <b-modal
      id="modal-user"
      size="xl"
      :title="secondPurpose == 'addClassGroupMember' ? 'Pilih Mahasiswa' : 'Pilih Peserta'"
      body-class="pt-0"
      @ok="formOnSubmit"
    >
      <!-- Tabs -->
      <div class="row">
        <div class="col-md-12">
          <Table
            @chooseUser="chooseUser"
            @checkAll="checkAll"
            :selectedItem="selectedItem"
            purpose="modal-user"
            :secondPurpose="secondPurpose"
            :checkbox="checkbox"
            :route="route"
            :roleId="filterParticipant.role_id == 2 && secondPurpose == 'addClassGroupMember' || secondPurpose == 'addParticipant' ? '2' : '-1'"
            :filterParticipant="filterParticipant"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/users/Table.vue";

export default {
  props: {
    checkbox: Boolean,
    selectedItem: Array,
    route: String,
    filterParticipant: Object,
    secondPurpose: String
  },

  components: {
    Table,
  },

  methods: {
    chooseUser(value) {
      this.$emit("chooseUser", value);
    },
    checkAll(value) {
      this.$emit("checkAll", value);
    },
    formOnSubmit(value) {
      this.$emit("formOnSubmit", value);
    },
  },

  data() {
    return {};
  },
};
</script>

<style>
</style>
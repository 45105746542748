<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container pb-0">
      <div class="row justify-content-between mb-8">
        <div>
          <h3 class="d-block">{{ detail.title }}</h3>
          <span class="text-muted">{{ detail.start_date + ' s/d ' + detail.due_date }}</span>
        </div>
        <div>
          <table>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Nama Siswa</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ studentName }}</span>
              </td>
            </tr>
            <tr>
              <td class="pr-1">
                <span class="font-weight-bold">Waktu Mengumpulkan</span>
              </td>
              <td class="pl-0 pr-0" width="10">
                <span class="font-weight-bold">:</span>
              </td>
              <td class="pl-2">
                <span>{{ availableAnswer[0].created_at_display }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <div class="container" v-if="assignmentLoaded">
      <div class="row">
        <form @submit.stop.prevent="formOnSubmitUpdate()">
          <b-table :items="availableAnswer" :fields="fieldAssignment" responsive>
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'number'
                      ? '1%'
                      : field.key === 'instruction'
                      ? '30%'
                      : field.key === 'student_answer'
                      ? '30%'
                      : field.key === 'mark'
                      ? '1%'
                      : '',
                }"
              />
            </template>

            <template #cell(instruction)>
              {{ detail.instruction }}
            </template>
            <template #cell(mark)>
              {{ detail.point }}
            </template>
            <template #cell(student_answer)="data">
              <!-- <div
                v-for="(attachment, index) in attachment_list_display"
                :key="attachment"
                class="col-md-12"
              >
                <b-card
                  class="shadow-sm"
                  v-if="attachment.length >= 1 && attachment[0] != ''"
                >
                  <a
                    download="file"
                    target="_blank"
                    :href="attachment_list_display[index]"
                    >{{ file_name[index] }}</a
                  >
                </b-card>
              </div> -->
              <span class="d-block">{{data.item.answer}}</span>
              <div
                v-for="(attachment, index) in attachment_list_display"
                :key="attachment"
                class="col-12"
              >
                <a
                  :href="attachment_list_display[index]"
                  download="file"
                  target="_blank"
                >
                  <b-card
                    no-body
                    class="overflow-hidden card-attach-image"
                    style="max-width: 540px"
                  >
                    <b-row no-gutters :class="'align-items-center'">
                      <b-col md="3">
                        <i
                          class="fas fa-file-pdf fa-4x"
                          v-if="attachment_extension[index] === 'pdf'"
                        ></i>
                        <i
                          class="fas fa-file-word fa-4x"
                          v-if="attachment_extension[index] === 'docx'"
                        ></i>
                      </b-col>
                      <b-col md="8">
                        <h6 class="text-dark">
                          {{ file_name[index] }}
                        </h6>
                        <span class="text-muted">{{
                          attachment_extension[index]
                        }}</span>
                      </b-col>
                    </b-row>
                  </b-card>
                </a>
              </div>
            </template>
            <template #cell(is_correct)="data">
              <b-button
                type="button"
                size="sm"
                :variant="
                  availableAnswer[data.index].is_active ==
                    true &&
                  availableAnswer[data.index].is_correct == 1
                    ? 'primary'
                    : 'default'
                "
                ><i class="fas fa-check fa-sm px-0"></i
              ></b-button>
            </template>

            <template #cell(is_wrong)="data">
              <v-hover v-slot="{ hover }">
                <b-button
                  type="button"
                  size="sm"
                  :variant="
                    availableAnswer[data.index].is_active ==
                      true &&
                    availableAnswer[data.index].is_correct == 0
                      ? 'danger'
                      : 'default'
                  "
                >
                  <template
                    v-if="
                      hover &&
                      availableAnswer[data.index].is_correct !=
                        0
                    "
                  >
                    <i class="fas fa-times fa-sm px-0 text-danger"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-times fa-sm px-0"></i>
                  </template>
                </b-button>
              </v-hover>
            </template>

            <template #cell(score)="data">
              <b-form-group
                :id="
                  'input-group-single-text'
                "
                :label-for="
                  'input-single-text'
                "
              >
                <b-form-input
                  :id="
                    'input-single-text'
                  "
                  v-model="availableAnswer[data.index].score"
                  disabled
                  placeholder="Skor"
                ></b-form-input>
              </b-form-group>
            </template>
          </b-table>
          <v-divider></v-divider>
          <!-- <b-button type="submit" variant="primary">Simpan</b-button> -->
          <b-button
            class="ml-2"
            type="button"
            variant="default"
            @click="handleContent"
            >Tutup</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from "@/view/components/general/Countdown.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import draggable from "vuedraggable";
export default {
  components: {
    Countdown,
    draggable,
  },
  props: {
    detail: Object,
    classroom_id: String,
    available_answer: Array,
    studentId: Number,
    studentName: String,
    availableAnswer: Array,
  },
  data() {
    return {
      fieldAssignment: [
        {
          key: "number",
          label: "",
        },
        { key: "instruction", label: "Tugas" },
        { key: "mark", label: "Acuan Skor" },
        { key: "student_answer", label: "Jawaban Murid" },
        { key: "is_correct", label: "Benar" },
        { key: "is_wrong", label: "Salah" },
        { key: "score", label: "Skor" },
      ],
      // other
      assignmentLoaded: true,
      attachment_list: [],
      attachment_list_display: [],
      attachment_extension: [],
      file_name: [],
      image_list: [],
      image_name: [],
      image_extension: [],
      // post
      postAssignment: {
        classwork_id: "",
        assignment_id: "",
        student_id: "",
        answer: "",
        is_correct: "",
        score: "",
        checked_by: "",
        file_uploaded: "",
        final_score: "",
        final_checked_by: "",
        is_finish: ""
      },
    };
  },
  methods: {
    handleContent() {
      this.$emit("handleContent", "list");
      this.$root.$emit("refreshScoreContent", "list")
      this.$root.$emit("handleContentRecapValue", "list");
    },
    handleCorrect(index, correct) {
      //("datanya", this.availableAnswer[index]);
      if (correct === 1) {
        this.availableAnswer[index].is_active = true;
        this.availableAnswer[index].is_correct = 1;
        this.availableAnswer[index].score =
          this.detail.point;
      } else if (correct === 0) {
        this.availableAnswer[index].is_active = true;
        this.availableAnswer[index].is_correct = 0;
        this.availableAnswer[index].score = 0;
      }
    },
    handleScore(index) {
      let default_score = parseInt(this.detail.point);
      let input_score = parseInt(this.availableAnswer[index].score);
      if (input_score > default_score) {
        this.availableAnswer[index].score = default_score
      }
    },
    setAttachmentList() {
      this.attachment_list = this.availableAnswer[0].file_uploaded.split(",");

      // //("attach", this.attachment_list);
      for (let c = 0; c < this.attachment_list.length; c++) {
        if (this.attachment_list[c] == "") {
          this.attachment_list.splice(c, 1);
        }
      }

      for (let a = 0; a < this.attachment_list.length; a++) {
        let splitExtension = this.attachment_list[a].split(".");
        let extension = splitExtension[splitExtension.length - 1];
        if (extension === "jpg" || extension === "png") {
          this.image_extension.push(splitExtension[splitExtension.length - 1]);
          this.image_list.push(this.attachment_list[a]);
          let image_name = this.attachment_list[a].split("____")[1];
          this.image_name.push(image_name.substring(0, 10) + "...");
        } else {
          this.attachment_extension.push(
            splitExtension[splitExtension.length - 1]
          );
          this.attachment_list_display.push(this.attachment_list[a]);
          let file_name = this.attachment_list[a].split("____")[1];
          this.file_name.push(file_name.substring(0, 10) + "...");
        }
      }
    },
    async formOnSubmitUpdate() {
      let user_data = JSON.parse(window.localStorage.getItem("user"));
      // postAssignment: {
      //   classwork_id: "",
      //   assignment_id: "",
      //   student_id: "",
      //   answer: "",
      //   is_correct: "",
      //   score: "",
      //   checked_by: "",
      //   file_uploaded: "",
      //   final_score: "",
      //   final_checked_by: "",
      // },
      this.postAssignment.classwork_id = this.detail.classwork_id;
      this.postAssignment.assignment_id = this.detail.assignment_id;
      this.postAssignment.student_id = this.studentId;
      this.postAssignment.answer = this.availableAnswer[0].answer
      this.postAssignment.is_correct = this.availableAnswer[0].is_correct;
      this.postAssignment.score = this.availableAnswer[0].score
      this.postAssignment.checked_by = user_data.id;
      this.postAssignment.file_uploaded = this.availableAnswer[0].file_uploaded
      this.postAssignment.final_score = this.availableAnswer[0].score;
      this.postAssignment.final_checked_by = user_data.id;
      this.postAssignment.is_finish = 2;
      this.postAssignment['_method'] = 'put'

      // Make Request
      let response = await module.submit(
          this.postAssignment, `api/classrooms/${this.classroom_id}/classworks/${this.detail.classwork_id}/classwork-assignment-results/${this.availableAnswer[0].id}`
        );
        // classrooms/{classroomId}/classworks/{classworkId}/classwork-assignment-results/{classworkAssignmentResultId}
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        this.$root.$emit("refreshClassworkAssignmentScore")
        Swal.fire(response.success.title, response.success.message, "success");
        this.handleContent();
      }
    },
  },
  mounted() {
    this.setAttachmentList();
  },
  watch: {
    studentId: function (newVal, oldVal) {
      // watch it
      //("student Id changed: ", newVal, " | was: ", oldVal);
    },
    detail: function (newVal, oldVal) {
      // watch it
      //("detail Id changed: ", newVal, " | was: ", oldVal);
      this.setAttachmentList();
    },
    availableAnswer: function (newVal, oldVal) {
      // watch it
      //("available answer changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 60px !important;
  height: 100px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper:hover {
  opacity: 0.8;
}

.card-attach-image:hover {
  opacity: 0.8;
  color: #1bc5bd !important;
}

.text-dark:hover {
  color: #1bc5bd !important;
}
</style>
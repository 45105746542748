import { render, staticRenderFns } from "./DetailAssignmentRecapValue.vue?vue&type=template&id=23612ddf&scoped=true&"
import script from "./DetailAssignmentRecapValue.vue?vue&type=script&lang=js&"
export * from "./DetailAssignmentRecapValue.vue?vue&type=script&lang=js&"
import style0 from "./DetailAssignmentRecapValue.vue?vue&type=style&index=0&id=23612ddf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23612ddf",
  null
  
)

export default component.exports